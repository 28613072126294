import React from "react"
import Typed from "react-typed"
import PropTypes from "prop-types"

import * as styles from "./search-modal.module.scss"

const exampleSearches = [
  "Barossa Shiraz",
  "French reds",
  "French wines",
  "Pinot Noir",
  "Search for wines^1000.^1000.^1000.",
]

const SearchInput = ({ onChange, ...inputProps }) => {
  return (
    <Typed
      strings={exampleSearches}
      startDelay={0}
      typeSpeed={100}
      backSpeed={100}
      backDelay={1500}
      showCursor={true}
      cursorChar="|"
      autoInsertCss={true}
      attr="placeholder"
      loop
    >
      <input
        {...inputProps}
        onChange={evt => onChange(evt.target.value)}
        type="text"
        className={styles.searchTerm}
        placeholder="Search for wines..."
      />
    </Typed>
  )
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default SearchInput
