// extracted by mini-css-extract-plugin
export var MuiSliderMark = "alignment-wrapper-module--MuiSlider-mark--18150";
export var MuiSliderMarkLabel = "alignment-wrapper-module--MuiSlider-markLabel--a7305";
export var MuiSliderRail = "alignment-wrapper-module--MuiSlider-rail--08d0f";
export var MuiSliderRoot = "alignment-wrapper-module--MuiSlider-root--78205";
export var MuiSliderThumb = "alignment-wrapper-module--MuiSlider-thumb--9fca8";
export var active = "alignment-wrapper-module--active--a8c7e";
export var alignBottom = "alignment-wrapper-module--alignBottom--cb7dd";
export var alignLeft = "alignment-wrapper-module--alignLeft--c3f1e";
export var alignMiddleHorizontal = "alignment-wrapper-module--alignMiddleHorizontal--b045b";
export var alignMiddleVertical = "alignment-wrapper-module--alignMiddleVertical--3a4cb";
export var alignRight = "alignment-wrapper-module--alignRight--a8cc9";
export var alignTop = "alignment-wrapper-module--alignTop--a3843";
export var alignmentWrapper = "alignment-wrapper-module--alignmentWrapper--3e97a";
export var amountSlider = "alignment-wrapper-module--amount-slider--b6d38";
export var bar = "alignment-wrapper-module--bar--4ea2d";
export var blank = "alignment-wrapper-module--blank--ae2ec";
export var btnShowWine = "alignment-wrapper-module--btn-show-wine--ce51f";
export var contentBody = "alignment-wrapper-module--content-body--e956c";
export var contentHeading = "alignment-wrapper-module--content-heading--db3d4";
export var dot = "alignment-wrapper-module--dot--751f7";
export var emailInput = "alignment-wrapper-module--email-input--bceec";
export var emailPage = "alignment-wrapper-module--email-page--4cad0";
export var introBody = "alignment-wrapper-module--intro-body--3e244";
export var introBtn = "alignment-wrapper-module--intro-btn--1e89d";
export var introTexts = "alignment-wrapper-module--intro-texts--f7f5e";
export var label = "alignment-wrapper-module--label--d0136";
export var last = "alignment-wrapper-module--last--70dda";
export var mark = "alignment-wrapper-module--mark--bf05a";
export var offersList = "alignment-wrapper-module--offers-list--b8e66";
export var optList = "alignment-wrapper-module--opt-list--46432";
export var pageContent = "alignment-wrapper-module--page-content--d5f98";
export var pageNavigator = "alignment-wrapper-module--page-navigator--14784";
export var questionItem = "alignment-wrapper-module--question-item--74f87";
export var questionText = "alignment-wrapper-module--question-text--08303";
export var questionaire = "alignment-wrapper-module--questionaire--4b9e8";
export var rankSelect = "alignment-wrapper-module--rank-select--42713";
export var ranking = "alignment-wrapper-module--ranking--b6ebd";
export var resultsPage = "alignment-wrapper-module--results-page--e7a1b";
export var slider = "alignment-wrapper-module--slider--b5541";
export var spending = "alignment-wrapper-module--spending--a2b58";
export var stepPages = "alignment-wrapper-module--step-pages--8a047";
export var stopBar = "alignment-wrapper-module--stop-bar--f86c5";
export var stops = "alignment-wrapper-module--stops--f1497";
export var subheading = "alignment-wrapper-module--subheading--69f36";
export var title = "alignment-wrapper-module--title--afadc";
export var toLogin = "alignment-wrapper-module--to-login--ea600";