import React from "react"

import "lib-vinomofo/styles/lib/reset.scss"
import "lib-vinomofo/styles/fonts.scss"
import "lib-vinomofo/styles/global.scss"

import { MarketContextWrapper } from "lib-vinomofo/contexts/market-context"
import LoadingOverlay from "lib-vinomofo/components/loading-overlay"
import { SearchModalWrapper } from "lib-vinomofo/contexts/search-modal-context"
import { SessionContextWrapper } from "lib-vinomofo/contexts/session-context"
import { CheckoutContextWrapper } from "lib-vinomofo/contexts/checkout-context"

export const wrapRootElement = ({ element }) => {
  return (
    <LoadingOverlay fadeOut>
      <MarketContextWrapper>
        <SessionContextWrapper>
          <CheckoutContextWrapper>
            <SearchModalWrapper>{element}</SearchModalWrapper>
          </CheckoutContextWrapper>
        </SessionContextWrapper>
      </MarketContextWrapper>
    </LoadingOverlay>
  )
}
